// Styles that override existing styles (mostly from MUI)

// Override seam.css container max-width, padding
.container {
  max-width: 1300px !important;
  padding: 0 1.5rem;
}

// Fixes the additional margin on the row display of MUIs
// data table (#144)
.MuiTablePagination-toolbar {
  & p.MuiTablePagination-displayedRows {
    margin-top: 0;
  }
}

.MuiPickersPopper-root {
  z-index: 999999999 !important;
}

// Google Maps Autocomplete menu
.pac-container {
  z-index: 999999999;
}

// Minor override for the OrganizationInfoModal so that
// the buttons are relative to the content (this is normally
// disabled because of the complications with screen-based
// navigation).
.org-info-modal {
  & .content {
    position: relative;
  }

  & .screen-wrap {
    height: 100%;
  }
}
