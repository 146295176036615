.org-switcher {
  width: 100%;
  padding: 0.75rem;
  position: relative;
  z-index: 2;

  & .trigger {
    appearance: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;
    background-color: var(--gray-base);
    border-radius: 6px;
    position: relative;

    // The fader doesn't transition very well since it's a background-image
    // change. We'll leave it out for now.
    // &,
    // & button,
    // & .fader {
    //   transition: var(--quick-2) var(--expressive);
    // }

    &:focus-within {
      border-color: var(--hyperlink-dark);
      box-shadow: 0px 0px 0px 3px rgba(182, 242, 255, 0.4);
    }

    &:hover {
      &,
      & button {
        background-color: var(--gray-hover);
      }

      & .fader {
        background-image: linear-gradient(
          to right,
          transparent,
          var(--gray-hover)
        );
      }
    }

    & input {
      appearance: none;
      min-width: 248px;
      width: 100%;
      max-width: 100%;
      height: 40px;
      background-color: transparent;
      padding: 0.25rem 0rem 0.25rem 0.75rem;
      border: none;
      box-shadow: none;

      &:focus {
        outline: none;
      }
    }

    & .adornment {
      height: 100%;
      position: absolute;
      right: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
    }

    & .fader {
      width: 2rem;
      height: 100%;
      background-image: linear-gradient(
        to right,
        transparent,
        var(--gray-base)
      );
    }

    & button {
      appearance: none;
      background-color: var(--gray-base);
      border: none;
      box-shadow: none;
      padding: 0;
      margin: 0;
    }

    & .chevron {
      transition: var(--quick-2) var(--expressive);
    }
  }
}
