.add-devices-modal {
  & .accounts-list {
    border: 1px solid var(--outer-border);

    & .row {
      height: 60px;
      display: flex;
      align-items: center;
      padding: 1rem;
      cursor: pointer;

      &:not(:first-of-type) {
        border-top: 1px solid var(--row-border);
      }

      &:hover {
        background: var(--row-hover-bg);
      }
    }

    & .linked-account {
      & .brand-image {
        width: 120px;
        text-align: center;
      }
    }
  }
}
