.modal-issues {
  padding: 0.75rem 1rem 1rem 1rem;
  margin-top: var(--modal-header-height);

  & .category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 0.5rem 1rem;
    background: #f3f5f7;

    & .content-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      & p.label {
        margin-right: 1rem;
      }

      & p.caption {
        font-size: 14px;
        line-height: 134%;
        color: #9da1a9;
      }

      & p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  & .row {
    width: 100%;
    appearance: none;
    background-color: transparent;
    border: none;
    border-top: 1px solid #eeeeee;
    box-shadow: none;
    display: flex;
    transition: var(--d-m-2) var(--e-s);

    &:hover {
      background-color: #f4f6f8;
    }

    &:focus-visible {
      background-color: #f4f6f8;
      box-shadow: 0px 1px 2px 3px #9da1a9;
      border-radius: 5px;
    }

    &:active {
      background-color: #dde0e2;
    }

    & .image-container {
      margin-right: 0.5rem;

      height: 48px;
      width: auto;
    }
  }

  & .row-container {
    width: 100%;
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    & .row-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;
      text-align: left;
    }
  }

  & .text-container {
    & h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: 134%;
      color: #232b3a;
      margin: 0;
    }

    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 134%;
      color: #9da1a9;
      margin: 0;
    }
  }

  & .issue-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    & * {
      margin: 0;
    }

    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 134%;
      color: #6e7179;
      margin-left: 0.25rem;
    }
  }
}
