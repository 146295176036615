html,
body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", "Inter", "Arial", system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif !important;
  color: var(--fg);
}

@mixin inner-shell {
  $nav-height: 72px;
  $footer-height: 110px;

  min-height: calc(100vh - $nav-height - $footer-height);
}

// All templates. It is imperative that templates
// set an appropriate classname (all current ones
// do).
div[class*="-template"] {
  background-color: white;
  @include inner-shell;
}

.mnh-inner-shell {
  @include inner-shell;
}

:root {
  --accent-light: #dad0f6;
  --accent: #7959d3;
  --accent-dark: #5a35c1;
  --accent-hover: var(--accent-dark);

  --fg-default: #232b3a;
  --fg-secondary: #6e7179;
  --fg-tertiary: #9da1a9;
  --fg-dimmed: #bec4cb;

  --gray-base: #f3f5f7;
  --gray-hover: #e9edef;
  --gray-active: #dde0e2;
  --danger: #e36857;
  --hyperlink: #6ac1ff;
  --hyperlink-dark: #078fdb;
  --row-bg: #f8fafd;
  --row-hover-bg: #f3f5f6;
  --row-border: #eeeeee;
  --outer-border: #dde0e2;
  --line-surface: #e7e0ec; // Used for separators
}
