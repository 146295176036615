.nav {
  width: 100%;
  height: 4.5rem;
  background-color: var(--bg);
  position: sticky;
  top: 0;
  transition: var(--quick-1) var(--expressive);
  z-index: 3000;

  &.has-scrolled {
    box-shadow: 0px 0px 10px 0px #18405e2e;
  }

  & .nav-container {
    width: 100%;
    height: 100%;
    padding: 1.25rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  & .nav-link {
    font-weight: 600;
    font-size: 16px;
    line-height: 134%;
    color: var(--fg);
    padding: 0 1rem;
  }
}

.desktop-menu {
  min-width: 200px !important;
  max-width: 270px !important;
  border-radius: 0.375rem !important;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 2px 16px 2px rgba(15, 22, 28, 0.15),
    0px 0px 1px 0px rgba(0, 0, 0, 0.3) !important;
  position: absolute;
  top: 3rem;
  right: 0;
  transform-origin: top right;

  & .overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(white, 0.75);
  }

  & .separator {
    width: 100%;
    height: 1px;
    background-color: #e7e0ec;
  }

  & .issue-block {
    width: 100%;
    padding: 0.5rem 0;
  }

  & .menu-action {
    appearance: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: 100%;
    height: 2.5rem;
    padding: 0 1.125rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    transition: var(--quick-1) var(--expressive);

    &:hover {
      background-color: #f3f5f6;
    }

    & span {
      font-size: 1rem;
      line-height: 1.4rem;
      color: #232b3a;
      white-space: nowrap;
    }
  }
}

.mobile-menu {
  width: 270px;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  box-shadow: var(--shadow-sm); // temporary
}

@mixin hide() {
  display: none;
  visibility: hidden;
}

@mixin show() {
  display: flex;
  visibility: visible;
}

.desktop-menu-wrapper,
.mobile-menu-wrapper {
  position: relative;
}

.desktop-menu-wrapper {
  // @include hide();
}

.mobile-menu-wrapper {
  @include hide();
}

@media only screen and (max-width: 720px) {
  .desktop-menu-wrapper {
    @include hide();
  }

  .mobile-menu-wrapper {
    @include show();
  }
}
