.modal-linked-accounts {
  height: 100%;
  display: flex;

  & .screen-wrap {
    width: 100%;
    height: 100%;
  }

  & .screen {
    width: 100%;
    // min-height: 100%;
    padding: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  & .text {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.3;
  }

  ///

  & .brand-box {
    width: 100%;
    border: 1px solid #dde0e2;

    & .brand-row {
      padding: 1rem 1.5rem;
      border-bottom: 1px solid #dde0e2;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      cursor: pointer;
      transition: var(--d-m-2) var(--e-s);

      &:hover {
        background-color: #e4e8eb;
      }

      &:last-of-type {
        border-bottom: none;
      }

      & .image-wrap {
        min-width: 140px;
        max-width: 140px;

        & img {
          height: 2rem;
          margin-right: 1rem;
        }
      }

      & span {
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }

  & .linking-image-wrap {
    gap: 1px;
  }

  & .small-caption {
    color: #9da1a9;
    font-size: 0.75rem;
    margin: 0;
  }

  & .login-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1rem;

    & label {
      font-weight: 600;

      &.disabled {
        opacity: 0.6;
      }
    }

    & input {
      appearance: none;
      border: none;
      box-shadow: none;
      width: 100%;
      height: 2.5rem;
      padding: 0 1rem;
      border-radius: 6px;
      background-color: #f3f5f7;

      &:disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }

  & .devices-list {
    max-height: 462px;
    border-radius: 10px;
    border: 1px solid #dde0e2;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;

    & .header {
      width: 100%;
      padding: 0.25rem 1rem;
      background-color: #dde0e2;
      position: sticky;
      top: 0;
      z-index: 2;

      & .header-action {
        appearance: none;
        background-color: transparent;
        color: #078fdb;
        font-size: 0.9rem;
      }

      & p {
        color: #6e7179;
        margin: 0;
      }
    }

    & .list {
      // max-height: 400px;
      // overflow-x: hidden;
      // overflow-y: scroll;
      // padding-bottom: 2rem;
    }

    & .device-row {
      height: 3.5rem;
      padding: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f8fafd;
      border-bottom: 1px solid #eeeeee;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  & .linking-image {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 0;
  }

  & .linking-logo {
    height: 5rem;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 90px;
    background-color: white;
    box-shadow: var(--shadow-sm);

    &:first-of-type {
      transform: translateX(2rem);
    }

    &:last-of-type {
      transform: translateX(-2rem);
    }

    & img {
      height: 100%;
    }
  }
}

.brand-image-wrap {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;

  & img {
    height: 100%;
  }
}
