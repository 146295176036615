.modal {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(black, 0.2);
  padding: 2rem;
  z-index: 4000;

  & .inner {
    max-height: 100%;
    width: 100%;
    max-width: 580px;
    background-color: white;
    box-shadow: 0px 2px 30px rgba(38, 41, 46, 0.35);
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  & .modal-header {
    padding: 0 16px 0 16px;
    display: grid;
    grid-template-columns: 0.25fr 1fr 0.25fr;
    background: linear-gradient(180deg, #ffffff 0%, #f1f5f6 100%);
    z-index: 10;

    & .grid-block {
      height: 100%;
    }

    & .label {
      font-weight: 600;
      font-size: 16px;
      line-height: 134%;
      text-align: center;
      color: #6e7179;
      margin: 0;
    }
  }

  & .header-button {
    background-color: transparent;
    padding: 0.5rem;
    transition: var(--d-m-2) var(--e-s);

    &:hover {
      opacity: 0.75;
    }
  }

  & .close-button {
    background-color: transparent;
    padding: 0.5rem;
    transition: var(--d-m-2) var(--e-s);

    &:hover {
      opacity: 0.75;
    }
  }

  & .description {
    color: var(--fg-secondary);
    text-align: center;
  }

  & .content {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;

    & .scrollable-content {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  & .content:focus {
    outline: none;
  }

  & .buttons {
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 1rem;
    gap: 0.75rem;

    & button {
      min-width: 114px;
    }
  }

  & .form-error {
    color: var(--danger);
    padding: 0.5rem 0;
    font-size: 0.875rem;
    line-height: 132%;
  }
}

@media only screen and (min-width: 768px) {
  .modal {
    padding: 6rem;
  }
}

@media only screen and (max-height: 600px) {
  .modal {
    padding: 2rem !important;
  }
}
