.home-page {
  & .content {
    height: 100%;
    display: flex;
    flex-direction: column;

    & .buildings-empty-overlay {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--fg-secondary);

      & svg {
        margin-bottom: 0.75rem;
      }

      & .title {
        font-weight: 600;
        line-height: 134%;
      }

      & .description {
        margin: 0;
      }

      & button {
        margin-top: 0.5rem;
      }
    }
  }
}
